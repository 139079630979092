import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  Search,
  LRestaurantPickup,
  RestaurantNav,
  LRestaurant,
  CPanelCard,
  LRestaurantPlan,
  CSectTitle,
  CNewsList,
  CBtnList,
  LRestaurantContact,
} from '../../components/_index';
import infoChoice from '../../utils/info-choice';
import infoGet from '../../utils/info-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: (
              <>
                RESTAURANT <br className="u_sp" />＆ BAR
              </>
            ),
            sub: 'レストラン＆バー',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/kv__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/restaurants/kv02.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/kv02__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/restaurants/kv03.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/kv03__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/restaurants/kv04.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/kv04__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/restaurants/kv05.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/kv05__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'レストラン＆バー',
            },
          }}
        />
      </CJumbotron>
      <Search formId="toppage" currentIndex={1} />
      <section id="seasonalMenu" className="l_sect">
        <LWrap>
          <p className="c_sectLead">
            横浜ランドマークタワー最上階のスカイラウンジ、68Fの和洋中のレストランをはじめ、
            <br className="u_pc" />
            ビジネスやプライベートのさまざまなシーンで使える飲食施設をご用意いたしております。
          </p>
          <RestaurantNav />
        </LWrap>
      </section>
      <section className="l_sect">
        <LRestaurant />
      </section>
      <LRestaurantPickup />
      <LRestaurantPlan />
      <section className="l_sect">
        <LWrap>
          <CSectTitle title={{ ja: 'お知らせ', en: 'INFORMATION' }} />
          <CNewsList
            data={infoChoice(infoGet(), ['レストラン&バー'], 5)}
            label={false}
          />
          <CBtnList
            data={[
              {
                label: 'お知らせ',
                link: {
                  href: '/information/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
            <li>料金には消費税、サービス料が含まれます。</li>
            <li>料理の表示価格は、特別な記載がない限り1名様分の料金です。</li>
            <li>
              テイクアウト商品の料金には消費税（8％）のみ含まれます。コフレ店内をご利用の場合は、サービス料を加算、消費税は10％となります。
            </li>
            <li>入荷状況によりメニュー内容に変更がある場合がございます。</li>
            <li>
              「神奈川県公共的施設における受動喫煙防止条例」に基づきレストラン・バーを全席禁煙とさせていただいております。
            </li>
            <li>
              ご入店の際は、タンクトップ・短パン・ビーチサンダルなどはご遠慮いただいております。
            </li>
          </ul>
        </LWrap>
      </section>
      <LRestaurantContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
